// extracted by mini-css-extract-plugin
export var fullWidth = "ProjectCard-module--full-width--2OrJ_";
export var fullWidthBleed = "ProjectCard-module--full-width--bleed--1wf4a";
export var navPadding = "ProjectCard-module--nav-padding--2G2ex";
export var mainGrid = "ProjectCard-module--main-grid--2OM5h";
export var mainGrid__sideCol = "ProjectCard-module--main-grid__side-col--3sdhU";
export var mainGrid__sideColPresist = "ProjectCard-module--main-grid__side-col--presist--2YV0G";
export var mainGrid__fullWidth = "ProjectCard-module--main-grid__full-width--26owq";
export var mainGrid__fullContent = "ProjectCard-module--main-grid__full-content--3Zsfe";
export var mainGrid__primaryCol = "ProjectCard-module--main-grid__primary-col--1dj7s";
export var mainGrid__primaryColCollapsable = "ProjectCard-module--main-grid__primary-col--collapsable--Ea0lQ";
export var mainGrid__primaryColLarge = "ProjectCard-module--main-grid__primary-col-large--1bykw";
export var mainGrid__primaryColSmall = "ProjectCard-module--main-grid__primary-col-small--2G0aN";
export var mainGrid__secondaryColSmall = "ProjectCard-module--main-grid__secondary-col-small--6ACQK";
export var mainGrid__secondaryColLarge = "ProjectCard-module--main-grid__secondary-col-large--22so4";
export var mainGrid__secondaryCol = "ProjectCard-module--main-grid__secondary-col--27v_k";
export var mainGrid__secondaryColMobileBreak = "ProjectCard-module--main-grid__secondary-col--mobile-break--mCWIS";
export var mainGrid__sectionSeperator = "ProjectCard-module--main-grid__section-seperator--1ZVO2";
export var mainGrid__col = "ProjectCard-module--main-grid__col--1C4Ob";
export var mainGrid__verticalLabel = "ProjectCard-module--main-grid__vertical-label--1_9TM";
export var displayFigure = "ProjectCard-module--display-figure--2aFKb";
export var displayFigureNoPadding = "ProjectCard-module--display-figure--no-padding--n9olE";
export var h1 = "ProjectCard-module--h1--3VYtR";
export var h2 = "ProjectCard-module--h2--3GlMN";
export var h4 = "ProjectCard-module--h4--3Egi3";
export var h5 = "ProjectCard-module--h5--1I593";
export var callout = "ProjectCard-module--callout--Zrfom";
export var label = "ProjectCard-module--label--r9vOR";
export var projectCardContent = "ProjectCard-module--projectCardContent--39zNB";
export var article__sectionHeader = "ProjectCard-module--article__section-header--2eVMb";
export var article__sectionNumber = "ProjectCard-module--article__section-number----mHX";
export var article__sectionDescription = "ProjectCard-module--article__section-description--cDv_B";
export var projectCard = "ProjectCard-module--projectCard--3_F1s";
export var projectCardMedium = "ProjectCard-module--projectCardMedium--2LQfH";
export var projectCardSmall = "ProjectCard-module--projectCardSmall--3PMPr";
export var projectCardLink = "ProjectCard-module--projectCardLink--btCoW";
export var projectType = "ProjectCard-module--projectType--bhW_2";
export var reactiveCard = "ProjectCard-module--reactiveCard--3ZWxH";